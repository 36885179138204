import React, { Component } from 'react'
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import { Form, Row, Col, Alert, Button, InputGroup, FormControl } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight, faArrowDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import '../style/guest.css'
import faunadb from 'faunadb'
import '../style/table.scss'
import MediaQuery from 'react-responsive'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
const q = faunadb.query
const client = new faunadb.Client({
    secret: 'fnAD30kaBUACBCJGdrPiXAHlLtlibF_S6aDSAK2B'
})
library.add(faArrowRight, faArrowDown, faSearch);

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.nama = React.createRef()
        this.state = {
            dataawal: [],
            datatampil: [],
            search: null,
            clicked: 'x',
            jumlah_hadir: [],
            jumlah_tidak_hadir: []
        }
    }
    async componentDidMount() {
        try {
           window.location.href="/tamu"
            }
         catch (e) {
            console.log(e)
        }
    }
    handleSubmit = () => {
        let nama = this.nama.current.value
        let { dataawal } = this.state
        if (nama == 'admin-alldata') {
            this.setState({
                datatampil: dataawal, search: true
            })
            localStorage.setItem('nama', nama)
        } else {
            let datatampil = dataawal.filter(val => { return val.mempelai == nama })

            if (datatampil.length > 0) {
                this.setState({
                    datatampil: datatampil, search: true
                })
                localStorage.setItem('nama', nama)
            } else {
                this.setState({
                    search: false
                })
            }
        }
    }
    render() {
        let { search, datatampil, clicked } = this.state
        let jumlah_hadir = datatampil.filter(v => {
            return v.hadir
        }).length
        let jumlah_tidak_hadir = datatampil.length - jumlah_hadir
        console.log(jumlah_hadir, jumlah_tidak_hadir)
        return (
            <>
                <div id='guest'>
                    <Container >
                        <h1>
                            Buku Tamu
                        </h1>
                        <InputGroup className="mb-3">
                            <FormControl ref={this.nama}
                                placeholder="Mempelai"
                                aria-label="Mempelai"
                                aria-describedby="basic-addon2"
                            />
                            <InputGroup.Append>
                                <Button onClick={() => { this.handleSubmit() }}
                                    variant="outline-secondary" className='m-2 bg-main'><FontAwesomeIcon icon={faSearch} className='mt-auto mb-auto' /></Button>
                            </InputGroup.Append>
                        </InputGroup>
                        <div className='hasil' >
                            {
                                search == false ? (
                                    <Alert variant='light'>
                                        Wah sepertinya ada yang salah, coba lagi!
                                    </Alert>
                                ) : (search == true ? (
                                    <>
                                        {/* {
                                        datatampil.map((val, i) => {
                                            return (
                                                <Alert className="d-md-none" variant='light' onClick={() => { clicked != i ? this.setState({ clicked: i }) : this.setState({ clicked: 'x' }) }}>
                                                    <FontAwesomeIcon icon={clicked == i ? faArrowDown : faArrowRight} className='mt-auto mb-auto mr-2' />

                                                    <b>
                                                        {val.nama}
                                                    </b>
                                                    {
                                                        clicked == i ? (
                                                            <Alert variant='secondary mt-3'>
                                                                <p>
                                                                    <b>
                                                                        Dari : {val.nama} <br />
                                                                    Kepada : {val.mempelai} <br />
                                                                    Alamat : {val.alamat}<br />
                                                                    Hadir : {val.hadir ? val.hadir : 'Tidak'}{`${val.sesi ? ` Sesi ${val.sesi}` : ''}`}<br />
                                                                        {val.with ? `With : ${val.with}` : ''} <br />
                                                                    </b>
                                                                " {val.pesan} "
                                                            </p>
                                                            </Alert>
                                                        ) : false
                                                    }
                                                </Alert>
                                            )
                                        })


                                    } */}
                                        <Row className="px-3">
                                            <Col>
                                                <p classname="mb-0 px-4 text-center">
                                                    Total Undangan : <br />
                                                    <b>{datatampil.length}</b>
                                                </p>
                                            </Col>
                                            <Col>
                                                <p classname="mb-0 px-4 text-center">
                                                    Undangan Hadir: <br />
                                                    <b>{jumlah_hadir}</b>
                                                </p>
                                            </Col>
                                            <Col>
                                                <p classname="mb-0 px-4 text-center">
                                                    Undangan Tidak Hadir: <br />
                                                    <b>{jumlah_tidak_hadir}</b>
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className="px-3">
                                            <ReactHTMLTableToExcel
                                                id="test-table-xls-button"
                                                className="download-table-xls-button btn btn-success my-2"
                                                table="table-to-xls"
                                                filename="undangankuDOTme"
                                                sheet="tablexls"
                                                buttonText="Download as XLS" />

                                        </Row>

                                        < table id="table-to-xls">

                                            <thead>
                                                <tr>
                                                    <th >No</th>
                                                    <th >Kepada</th>
                                                    <th>Dari</th>
                                                    <th >Alamat</th>
                                                    <th >Hadir</th>
                                                    <th >Jumlah Hadir</th>
                                                    <th >Pesan</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    datatampil.map((val, i) => {
                                                        return (
                                                            <>
                                                                <MediaQuery minDeviceWidth={900}><tr>
                                                                    <td data-head='No'>{i + 1}</td>
                                                                    <td data-head='Kepada'>{val.mempelai}</td>
                                                                    <td data-head='Dari'>{val.nama}</td>
                                                                    <td data-head='Alamat'>{val.alamat}</td>
                                                                    <td data-head='Hadir'>{val.hadir ? `Hadir ${val.sesi ? ` Sesi ${val.sesi}` : ''}` : 'Tidak'}</td>
                                                                    <td data-head='With'>{val.with ? val.with : ''}</td>
                                                                    <td data-head='Pesan'>{val.pesan}</td>
                                                                </tr>
                                                                </MediaQuery>
                                                                <MediaQuery maxDeviceWidth={900}>
                                                                    <tr>
                                                                        <td data-head='No' className={` ${clicked == i ? 'd-block' : 'd-none'}`}>{i + 1}</td>
                                                                        <td data-head='Kepada' className={` ${clicked == i ? 'd-block' : 'd-none'}`}>{val.mempelai}</td>
                                                                        <td data-head='Dari'
                                                                            onClick={() => {
                                                                                if (clicked == 1) {
                                                                                    this.setState({ clicked: -1 })
                                                                                } else {
                                                                                    this.setState({ clicked: i })
                                                                                }
                                                                            }}

                                                                        >{val.nama}</td>
                                                                        <td data-head='Alamat' className={` ${clicked == i ? 'd-block' : 'd-none'}`}>{val.alamat}</td>
                                                                        <td data-head='Hadir' className={` ${clicked == i ? 'd-block' : 'd-none'}`}>{val.hadir ? `Hadir ${val.sesi ? ` Sesi ${val.sesi}` : ''}` : 'Tidak'}</td>
                                                                        <td data-head='With' className={` ${clicked == i ? 'd-block' : 'd-none'}`}>{val.with ? val.with : ''}</td>
                                                                        <td data-head='Pesan' className={` ${clicked == i ? 'd-block' : 'd-none'}`}>{val.pesan}</td>
                                                                    </tr>
                                                                </MediaQuery>

                                                            </>)


                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </>) : (false))
                            }
                        </div>
                    </Container>


                </div>
            </>
        )
    }
}